<template>
  <div class="main">
    <div class="clients content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/dashboard"><span>Dashboard</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> Client </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="add">
            <a-button type="primary">
              <router-link to="/clients/add"><UserAddOutlined /> Add Client</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row class="widgets" v-if="statistics">
        <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="widget">
            <div class="no_of_client">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <img
                    src="../../../assets/images/dashboard/user.svg"
                    alt="No of Client"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistics.no_of_clients}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">No of clients</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="widget">
            <div class="no_of_active_clients">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <img
                    src="../../../assets/images/dashboard/file.svg"
                    alt="No of Course"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistics.active_clients}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">Active clients</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="widget">
            <div class="no_of_inactive_clients">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <img
                    src="../../../assets/images/dashboard/users.svg"
                    alt="No of Candidate"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistics.inactive_clients}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">Inactive clients</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="widget">
            <div class="expired_clients">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <img
                    src="../../../assets/images/dashboard/users.svg"
                    alt="No of Candidate"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistics.expired_clients}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">Expired clients</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        </a-row>
      <ListClient @refreshStatistics="refreshStatistics" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, UserAddOutlined } from '@ant-design/icons-vue'

import ListClient from '@/components/agency/client/ListClient.vue'
import ClientService from '../../../services/profile'

interface ClientStatistc {
  no_of_clients: number;
  active_clients: number;
  inactive_clients: number;
  expired_clients: number;
}

export default defineComponent({
  components: {
    HomeOutlined,
    UserAddOutlined,
    ListClient
  },
  setup () {
    const statistics = ref<ClientStatistc>()
    const getClientStatistics = async () => {
      const responce: any = await ClientService.getStatistics()
      statistics.value = responce.data
    }
    const refreshStatistics = async () => {
      getClientStatistics()
    }
    onMounted(() => {
      getClientStatistics()
    })
    return {
      statistics,
      getClientStatistics,
      refreshStatistics
    }
  }
})
</script>

<style lang="scss">
.clients {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .widget {
    padding: 20px 10px;
    color: #ffffff;
    .no_of_client,
    .no_of_active_clients,
    .no_of_inactive_clients,
    .expired_clients {
      min-height: 125px;
      border-radius: 10px;
      padding: 15px;
      text-align: left;

      img {
        width: 50px;
      }

      .value {
        font-size: 40px;
        font-weight: 400;
        text-align: right;
        padding-right: 5px;
      }

      .text {
        font-weight: bold;
        margin-top: 5px;
      }

      .time {
        font-size: 12px;
      }
    }
    .no_of_client {
      background-image: linear-gradient(to right, #8d2ce2, #4b01e0);
    }
    .no_of_active_clients {
      background-image: linear-gradient(to right, #ff9866, #ff5f62);
    }
    .no_of_inactive_clients {
      background-image: linear-gradient(to right, #36d0dc, #5b87e5);
    }
    .expired_clients {
      background-image: linear-gradient(to right, #8d2ce2, #4b01e0);
    }
  }
}
</style>
